<template>
  <div class="news">
    <div class="newsCenter">
      <div class="topImg">
        <el-carousel @change="cs" :interval="5000" :setActiveItem="chooseIndex" height="450px" ref="carousel">
          <el-carousel-item v-for="(item,index) of data" :key="index">
            <img class="bigImg" @click="()=>{ $router.push('/message/'+ item.id +'?id='+item.id) }" :src="item.picturePath" alt="">
          </el-carousel-item>
        </el-carousel>
        <div class="f4Urm1TitleBox">
          <p @click="setActiveItem1(index)" :style="chooseIndex === index ? 'opacity: 0.8' : ''" class="f4Urm1Title" v-for="(item,index) of data" :key="index+'j'">{{ item.title.slice(0,16) }}</p>
        </div>
      </div>
      <div class="bottomCenter">
        <div class="title"><strong>最新动态</strong></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      data: [],
      chooseIndex: 0
    }
  },
  methods:{
    cs(e){
      this.chooseIndex = e
    },
    setActiveItem1(e){
      this.$refs.carousel.setActiveItem(e)
    }
  }
}
</script>
<style lang="less">
.news{
  .newsCenter{
    width: 1200px;
    margin: 20px auto;
    .topImg{
      position: relative;
      padding-top: 30px;
      height: 450px;
      .el-carousel__indicators--horizontal{
        display: none;
      }
      .bigImg{
        cursor: pointer;
        width: 1200px;
        height: 450px;
      }
      .f4Urm1TitleBox{
        cursor: pointer;
        position: absolute;
        z-index:2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 45px;
        font-size: 12px;
        .f4Urm1Title{
          text-align: center;
          float: left;
          width: 140px;
          padding: 0 50px;
          height: 45px;
          line-height: 45px;
          color:#FFFFFF;
          background: rgba(0,0,0,0.5);
          overflow: hidden;
        }
        .f4Urm1Title:nth-child(2){
          border-left:1px solid rgba(255,255,255,0.3);
          border-right:1px solid rgba(255,255,255,0.3);
          width: 138px;
          padding: 0 50px;
        }
        .f4Urm1Title:nth-child(3){
          border-right:1px solid rgba(255,255,255,0.3);
          width: 139px;
          padding: 0 50px;
        }
        .f4Urm1Title:nth-child(4){
          border-right:1px solid rgba(255,255,255,0.3);
          width: 139px;
          padding: 0 50px;
        }
      }

    }
    .bottomCenter{
      width: 100%;
      .title{
        width: 880px;
        float: left;
        text-align: left;
        font-family: MicrosoftYaHei-Bold;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #112a63;
        padding-bottom: 40px;
      }
    }
  }
}
</style>
